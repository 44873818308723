import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import Login from "./pages/Login";
import AuthStore from "./store/AuthStore";
import Catalog from "./pages/Catalog";
import Admin from "./pages/Admin";

function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Catalog />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/admintable" element={<Admin />}></Route>
          <Route path="/*" element={<Navigate to="/" replace={true} />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default observer(App);
