import { Toast } from "antd-mobile";
import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import AuthStore from "../store/AuthStore";

const instance = Axios.create();

export const getToken = () => {
  const token = localStorage.getItem("token");
  return `Bearer ${token}`;
};

const success = (res: AxiosResponse) => {
  if (res.status === 200) {
    if (res?.data?.code === 401) {
      localStorage.removeItem("token");
      AuthStore.setCurDaka(0);
      document.location.href = "/";
      Toast.show({
        icon: "fail",
        content: "token异常",
      });
    }
    return res.data;
  } else {
    throw new Error();
  }
};

const fail = (err: AxiosError) => {
  if (err?.response?.status === 401) {
    localStorage.removeItem("token");
    AuthStore.setCurDaka(0);
    document.location.href = "/";
  } else if (err.code === "ERR_NETWORK") {
    Toast.show({
      icon: "fail",
      content: "网络错误",
    });
  }
};

const filterData = (data: any) => {
  if (data instanceof FormData) return data;

  const tmp = { ...data };
  for (const key in tmp) {
    if (tmp[key] === null || tmp[key] === undefined || tmp[key] === "") {
      delete tmp[key];
    }
  }
  return tmp;
};

export const post = async (
  url: string,
  data: any,
  withToken?: boolean,
  config?: AxiosRequestConfig
) => {
  config = {
    ...(config || {}),
    headers: {
      ...(config?.headers || {}),
      Authorization: withToken ? getToken() : undefined,
    },
  };

  return instance
    .post(url, filterData(data), {
      ...config,
    })
    .then(success)
    .catch(fail);
};

export const get = async (
  url: string,
  params: any,
  withToken: boolean = false,
  config?: AxiosRequestConfig
) => {
  config = {
    ...(config || {}),
    headers: {
      ...(config?.headers || {}),
      Authorization: withToken ? getToken() : undefined,
    },
  };

  return instance
    .get(url, {
      params,
      ...config,
    })
    .then(success)
    .catch(fail);
};
